import React, { useEffect, useContext, useState } from "react"

import "../styles/templates/glasses-product.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OrderFlow from "../components/OrderFlow"
import CartContext from "../context/cartContext"
import GlassesInfo from "../components/GlassesInfo"
import ProductValueProps from "../components/ProductValueProps"
import Breadcrumbs from "../components/Breadcrumbs"
import AboutProductSection from "../components/AboutProductSection"
import VideoModule from "../components/VideoModule"
import ProductWithHubble from "../components/ProductWithHubble"
import ProductIncluded from "../components/ProductIncluded"
import FAQBlock from "../components/FAQBlock"
import ReviewCarousel from "../components/ReviewCarousel"
import { framesInfoDictionary } from "../services/framesInfoDictionary"
import {
  glassesFaq,
  colorTags,
  glassesValueProps,
  glassesWithHubble,
} from "../services/data"
import RelatedFrames from "../components/RelatedFrames"

const GlassesProduct = ({ pageContext }) => {
  const {
    title,
    glassesBasePrice,
    type,
    productClothImage,
    relatedFrames,
    hubbleFrameVariants,
    handle,
    kidsFrames,
    size
  } = pageContext
  const isKidsSize = size.toLowerCase() === "kids"
  const frameInfoMeta = framesInfoDictionary.find(obj => {
    return obj.title === title
  })

  const mockedAccessoryItem = {
    node: {
      type: "accessory",
      price: 7.99,
      handle: "2-oz-classic-lens-care-kits",
      productImage1:
        "https://cdn.shopify.com/s/files/1/1516/5228/products/Classic-Lens-Care-Kit-PDP.png?v=1672763145",
      title: "Classic Lens Care Kits",
    },
  }

  const relatedFramesArr = [...relatedFrames.edges, mockedAccessoryItem].map(({ node }) => node)
  const [relatedProducts, setRelatedProducts] = useState([])
  const [framesInfo, setFramesInfo] = useState()

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts | Hand-Crafted Glasses and Sunglasses",
      "description": "Here at Hubble, we’re not only about getting you the contact lenses you need right when you need them--we’re also out to re-frame your whole perspective on eyewear. And with a variety of glasses and sunglasses to choose from—you could say that we sort of have an eye for it.",
      "thumbnailUrl": "https://i.ytimg.com/vi/MRO_-HrfY70/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "embedUrl": "https://www.youtube.com/embed/MRO_-HrfY70"
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Can I order multiple pairs of glasses from Hubble with different prescriptions?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, absolutely! You can add as many pairs of glasses (and sunglasses) to your cart before you check out."
          }
        },
        {
          "@type": "Question",
          "name": "Where are Hubble’s glasses made?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our custom Hubble frames are proudly designed in-house in NYC and hand-assembled in Wisconsin. We custom-cut, polish, and mount lenses into their frames before shipping them directly to you. Our Hubble-approved frames, while not developed in-house, are thoughtfully sourced from a family-owned, top-level manufacturer in the Chicagoland area."
          }
        },
        {
          "@type": "Question",
          "name": "What type of lenses does Hubble offer for eyeglasses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "For eyeglasses, we offer polycarbonate, 1.67 high-index, and blue light-filtering lenses."
          }
        },
        {
          "@type": "Question",
          "name": "I need thin lenses with my prescription. Does Hubble offer thin lenses for eyeglasses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, Hubble offers 1.67 high-index lenses, which are recommended for anyone with a strong prescription (greater than or equal to +4.0 or -4.0 power). After you select your preferred frame, you can select a 1.67 high-index lens during the checkout process."
          }
        },
        {
          "@type": "Question",
          "name": "What's the best way to clean my Hubble eyeglass lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can clean your lenses with warm water and a small drop of mild dish soap. Then, dry with a clean, soft cotton cloth, like the microfiber lens cloth that came with your purchase. You can also use the Frames by Hubble lens cloth to wipe off light smudges on the regular."
          }
        }
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": `${title} Eyeglass Frames"`,
      "image": hubbleFrameVariants[0].cartImage,
      "description": frameInfoMeta?.glassesMetaDescription,
      "brand": {
        "@type": "Thing",
        "name": "Hubble"
      },
      "logo": "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg?v=14192704095517354305",
      "offers": {
        "@type": "Offer",
        "url": `https://hubblecontacts.com/glasses/${title.toLowerCase()}/`,
        "availability": "https://schema.org/InStock",
        "price": "49.99",
        "priceCurrency": "USD"
      },
      "model": `${title}`,
      "color": `${hubbleFrameVariants.map((e) => e.title)}`,
    },
    {
      "@context": "https://schema.org",
      "@url": `https://hubblecontacts/glasses/${handle}`,
      "@type": "WebPage",
      "name": title,
      "description": frameInfoMeta?.glassesMetaDescription,
      "publisher": {
        "@type": "Organization",
        "name": "Hubble"
      }
    }
  ]

  const { cartItems, setHeaderColor, createOrder, clearOrder } = useContext(
    CartContext
  )
  const [show, setShow] = useState(false)
  const [currentVariant, setCurrentVariant] = useState(hubbleFrameVariants[0])



  useEffect(() => {
    const currentItem = framesInfoDictionary.find(obj => {
      return obj.title === title
    })
    setFramesInfo(currentItem)
  }, [title])

  const handleSelect = () => {
    const newItem = {
      ...pageContext,
      variant: currentVariant,
      price: glassesBasePrice,
      image: !isKidsSize ?
              `Frames/Glasses/Hubble-Glasses-${title}-${currentVariant?.title?.split(" ").join("-")}-full.png` :
              `Frames/KidsGlasses/Hubble-Glasses-Kids-${title}-${currentVariant?.title?.split(" ").join("-")}-full.png`
    }

    const newOrder = {
      prescriptionType: "",
      lensType: "",
      material: "",
      prescription: {
        rightEye: {
          sphere: "0",
          cylinder: "+0.0",
          axis: "0",
        },
        leftEye: {
          sphere: "0",
          cylinder: "+0.0",
          axis: "0",
        },
        pd: {
          pd: "62",
        },
      },
    }

    createOrder(newItem, newOrder)
    setShow(true)
    if (typeof window !== 'undefined') {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: pageContext.shopifyProductId,
              item_name: title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              index: 0,
              item_category3: pageContext.productGender,
              item_category4: pageContext.material,
              item_category5: pageContext.size,
              item_list_id: `${pageContext.productGender}-Optical`,
              item_variant: currentVariant.title,
              price: parseFloat(pageContext.sunglassesBasePrice),
              quantity: 1
            }
          ]
        }
      })
    }


  }

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
      clearOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  useEffect(() => {
    if (isKidsSize) {
      const kidsGlasses = kidsFrames.filter((el) => el.handle !== handle).slice(0, 3)
      setRelatedProducts([...kidsGlasses, mockedAccessoryItem.node])
    } else {
      setRelatedProducts(relatedFramesArr)
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_id:        pageContext.shopifyProductId,
            item_name:      title,
            currency:       'USD',
            discount:       0,
            index:          0,
            item_brand:     'Hubble',
            item_category:  "frames",
            item_category2: 'optical',
            item_category3: pageContext.productGender,
            item_category4: pageContext.material,
            item_category5: pageContext.size,
            item_list_id:   'Optical',
            item_variant:   'N/A',
            price:          glassesBasePrice
          }
        ]
      }
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout className="glasses-product">
      <SEO title={`${frameInfoMeta?.glassesMetaTitle || "Eyeglasses | Frames By Hubble" }`}
        description={`${frameInfoMeta?.glassesMetaDescription || "Check out stylish and affordable glasses by your friends at Hubble" }`}
        schemaMarkup={schemaMarkup} />
      <GlassesInfo
        item={{ ...pageContext, price: glassesBasePrice }}
        onSelect={handleSelect}
        variant={currentVariant}
        setVariant={setCurrentVariant}
      />
      <ProductValueProps props={glassesValueProps} type={type} />
      <div className="about-section">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "Homepage" },
              { to: "/glasses", label: "Eyeglasses" },
              { label: `The ${title}` },
            ]}
          />
        </div>
        <AboutProductSection
          title={title}
          type={type}
          description={framesInfo?.description}
          bullet_points={framesInfo?.bullet_points}
          image="Pages/GlassesPDP/Hubble-Glasses-PDP-Inset-Lifestyle-Image-Kepler.jpg"
        />

        <ProductWithHubble type={type} items={glassesWithHubble} />
        <ProductIncluded type={type} image={productClothImage} title={title} />
        <VideoModule
          type={type}
          video={"https://www.youtube.com/embed/MRO_-HrfY70"}
          image="Pages/GlassesPDP/Hubble-Glasses-PDP-Video-Still.jpg"
        />
        <FAQBlock faqList={glassesFaq} />
        <ReviewCarousel color={colorTags[type]} page="glasses" />
        <RelatedFrames
          relatedFrames={relatedProducts}
          type={type}
          linkTo="/glasses"
          isKidsRelatedGlasses={isKidsSize}
        />
      </div>
      <OrderFlow type={type} show={show} setShow={setShow} />
    </Layout>
  )
}

export default GlassesProduct
